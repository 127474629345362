<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import {mapState} from "vuex";


export default {
  page: {
    title: "Account",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    AccountGeneral: () => import('@/components/lazy/account/AccountGeneral'),
  },
  validations: {

  },
  methods: {

  },
  created() {

  },
  data() {
    return {

    }
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">
        Account
      </h4>
      <div class="page-title-right bv-d-xs-down-none">
        <ol class="breadcrumb m-0">
        <li class="breadcrumb-item">
          <router-link to="/">
            <span class="text-danger text-uppercase">
              <i class="fad fa-clipboard-user" />
              CFTools Internal
            </span>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span aria-current="location">
            Account
          </span>
        </li>
      </ol>
      </div>
    </div>
    <AccountGeneral></AccountGeneral>
  </Layout>
</template>